<template>
  <div v-if="data.ID">
    <div class="flix-btn-group">
      <a href="#/" @click="function() { onEdit(data.ID) }" class="flix-btn flix-btn-default"><flixIcon id="cogwheel" /> {{ $t('message.edit', {name: $tc('message.targetPage', 1)}) }}</a>
      <a :href="url" target="_blank" class="flix-btn flix-btn-default"><flixIcon id="eye-open" /> {{ $t('message.preview') }}</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    onEdit: Function,
    data: Object
  },
  mounted () {
    this.getUrl()
  },
  computed: {

  },
  data () {
    return {
      url: '',
      variables: this.$getUserVariables()
    }
  },
  methods: {
    getUrl () {
      this.url = this.$flix_url + '/' + this.$i18n.locale + '/' + this.variables.user.username + '/' + this.data.ID + '/index.html'
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
